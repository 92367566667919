var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-height"},[_c('v-app-bar',{attrs:{"app":"","color":"primary"}},[_c('div',{staticClass:"on-primary--text"},[_vm._v(" "+_vm._s(_vm.titleToShow)+" ")]),_c('span',{staticClass:"ml-auto on-primary--text"},[(_vm.currentUser.isAdmin)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-6",attrs:{"color":"on-primary"}},on),[_vm._v("fas fa-user-cog")])]}}],null,false,1396663320)},[_c('span',[_vm._v(_vm._s(_vm.$t('GENERAL.YOU_ARE_AN_ADMIN')))])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-3 mr-6 pointer",attrs:{"color":"on-primary"},on:{"click":function($event){return _vm.onLogout()}}},on),[_vm._v("fas fa-sign-out-alt")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('GENERAL.LOGOUT')))])])],1)]),_c('div',{staticClass:"d-flex fill-height"},[_c('v-navigation-drawer',{staticClass:"d-flex flex-column",attrs:{"permanent":"","mini-variant":"","clipped":"","mini-variant-width":64}},[_c('v-list',{attrs:{"dense":""}},[(_vm.isAdmin)?_c('div',_vm._l((_vm.adminNavMenuItems),function(item){return _c('v-list-item',{key:item.name,class:{ 'is-active' : item.isActive},attrs:{"ripple":false},on:{"click":function($event){return _vm.onNavItemClick(item)}}},[_c('v-list-item-icon',{staticClass:"zero-margin"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"size":"28","color":item.isActive ? 'primary' : 'secondary'}},on),[_vm._v(" "+_vm._s(item.icon)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t(item.name)))])])],1)],1)}),1):_vm._e(),(_vm.isAdmin)?_c('v-divider',{staticClass:"my-2"}):_vm._e(),(!_vm.currentUser.isAdmin || (_vm.currentUser.isAdmin && _vm.selectedCompany))?_c('div',_vm._l((_vm.navigationElements),function(item){return _c('v-list-item',{key:item.name,staticClass:"pa-1 nav-item",class:{ 'is-active' : item.isActive},attrs:{"ripple":false},on:{"click":function($event){return _vm.onNavItemClick(item)}}},[_c('v-list-item-icon',{staticClass:"zero-margin"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"size":"28","color":item.isActive ? 'primary' : 'secondary'}},on),[_vm._v(" "+_vm._s(item.icon)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t(item.name)))])])],1)],1)}),1):_vm._e()],1),_c('div',{staticClass:"flex-grow-1 d-flex align-end justify-center pb-4 description-text"},[_vm._v(" v"+_vm._s(_vm.appVersion)+" ")])],1),_c('v-container',{staticClass:"fill-height align-start",attrs:{"fluid":""}},[_c('router-view')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }