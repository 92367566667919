


























































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { authStoreGetter } from '@/store/auth.store';
import User from '@/models/User';
import { NavigationMenuItemInterface } from '@/interfaces/NavigationMenuItem.interface';
import { ROUTE_LOGIN } from '@/router/routes';
import { applicationStoreGetter } from '@/store/application.store';
import Company from '@/models/Company';
import { UserRoles } from '@/enum/UserRoles';
import { adminNavigationMenuItems, navigationMenuItems } from '@/misc/navigationMenuItems';

// @ts-ignore
import { version } from '../../package';

const AuthStore = namespace('auth');
const ApplicationStore = namespace('application');

@Component({})
export default class HomeView extends Vue {

    @AuthStore.Getter(authStoreGetter.CURRENT_USER)
    public currentUser!: User;

    @ApplicationStore.Getter(applicationStoreGetter.SELECTED_COMPANY)
    public selectedCompany!: Company;

    public navigationMenuItems: NavigationMenuItemInterface[] = [];
    private adminNavMenuItems: NavigationMenuItemInterface[] = adminNavigationMenuItems;

    private get appVersion() {
        return version;
    }

    @Watch('$route', { immediate: true })
    private onRouteChange() {
        this.$nextTick(() => {
            const allNavItems = [...this.navigationMenuItems, ...this.adminNavMenuItems];
            allNavItems.forEach(item => {
                item.isActive = false;
            });
            const activeNavItem = allNavItems.find(item => item.route === this.$route.name);
            if (!activeNavItem) return;
            activeNavItem!.isActive = true;
        });
    }

    private get navigationElements() {
        const user: User = this.$getCurrentAccount();
        if (!user) return;
        const items = navigationMenuItems.filter((item) => item.roles.includes(user.role ??
            UserRoles.COMPANY_MANAGER));
        this.navigationMenuItems = items.filter((i) => !i.isCompanyBased || this.selectedCompany);
        return this.navigationMenuItems;
    }

    /**
     * Event handler for click on nav item
     */
    public onNavItemClick(item: NavigationMenuItemInterface) {
        this.$router.push({ name: item.route });
    }

    /**
     * On logout handler
     */
    public async onLogout() {
        // Reset each store module
        await this.$router.push({ name: ROUTE_LOGIN });
        Object
            // @ts-ignore
            .keys(this.$store._modulesNamespaceMap)
            .forEach(key => {
                this.$store.commit(`${key}CLEAR_STORE`);
            });
    }

    private get titleToShow() {
        const isAdminView = this.adminNavMenuItems.find((i) => i.route === this.$route.name);
        if (isAdminView) return 'General settings';
        if (!this.selectedCompany) return this.$t('GENERAL.NO_COMPANY');
        return this.selectedCompany.name;
    }

    private get isAdmin() {
        return this.$hasRole(UserRoles.ADMIN);
    }
}
