/**
 * All navigation menu items
 */
import { NavigationMenuItemInterface } from '@/interfaces/NavigationMenuItem.interface';
import {
    ROUTE_BILLING,
    ROUTE_COMPANY,
    ROUTE_DASHBOARD,
    ROUTE_DRIVER,
    ROUTE_MANAGER_MANAGEMENT,
    ROUTE_NOTIFICATIONS,
    ROUTE_PROMOTERS,
    ROUTE_RIDE,
    ROUTE_SHARE_MANAGEMENT,
    ROUTE_STATISTICS,
    ROUTE_TAXI,
    ROUTE_TRANSACTIONS
} from '@/router/routes';
import { UserRoles } from '@/enum/UserRoles';

export const adminNavigationMenuItems: NavigationMenuItemInterface[] = [
    {
        name: 'NAVIGATION_ITEMS.SHARE_MANAGEMENT',
        route: ROUTE_SHARE_MANAGEMENT,
        icon: 'fas fa-gear',
        isActive: false,
        roles: [UserRoles.ADMIN],
        isCompanyBased: false
    },
    {
        name: 'NAVIGATION_ITEMS.PROMOTERS',
        route: ROUTE_PROMOTERS,
        icon: 'fas fa-people-robbery',
        isActive: false,
        roles: [UserRoles.ADMIN],
        isCompanyBased: false
    },
    {
        name: 'NAVIGATION_ITEMS.USER_MANAGEMENT',
        route: ROUTE_MANAGER_MANAGEMENT,
        icon: 'fas fa-people-roof',
        isActive: false,
        roles: [UserRoles.ADMIN],
        isCompanyBased: false
    },
    {
        name: 'NAVIGATION_ITEMS.TRANSACTIONS',
        route: ROUTE_TRANSACTIONS,
        icon: 'fas fa-money-bill',
        isActive: false,
        roles: [UserRoles.ADMIN],
        isCompanyBased: false
    },
    {
        name: 'NAVIGATION_ITEMS.STATISTICS',
        route: ROUTE_STATISTICS,
        icon: 'fas fa-chart-line',
        isActive: false,
        roles: [UserRoles.ADMIN],
        isCompanyBased: false
    },
    {
        name: 'NAVIGATION_ITEMS.NOTIFICATIONS',
        route: ROUTE_NOTIFICATIONS,
        icon: 'fas fa-bell',
        isActive: false,
        roles: [UserRoles.ADMIN],
        isCompanyBased: false
    }
];

export const navigationMenuItems: NavigationMenuItemInterface[] = [
    {
        name: 'NAVIGATION_ITEMS.BILLING',
        route: ROUTE_BILLING,
        icon: 'fas fa-hand-holding-usd',
        isActive: false,
        roles: [UserRoles.ADMIN, UserRoles.MANAGER],
        isCompanyBased: false
    },
    {
        name: 'NAVIGATION_ITEMS.COMPANIES',
        route: ROUTE_COMPANY,
        icon: 'fas fa-building',
        isActive: false,
        roles: [UserRoles.ADMIN, UserRoles.MANAGER],
        isCompanyBased: false
    },
    {
        name: 'NAVIGATION_ITEMS.DASHBOARD',
        route: ROUTE_DASHBOARD,
        icon: 'fas fa-gauge',
        isActive: false,
        roles: [UserRoles.ADMIN, UserRoles.COMPANY_MANAGER, UserRoles.MANAGER],
        isCompanyBased: true
    }, {
        name: 'NAVIGATION_ITEMS.DRIVER',
        route: ROUTE_DRIVER,
        icon: 'fas fa-id-card',
        isActive: false,
        roles: [UserRoles.ADMIN, UserRoles.COMPANY_MANAGER, UserRoles.MANAGER],
        isCompanyBased: true
    },
    {
        name: 'NAVIGATION_ITEMS.TAXIS',
        route: ROUTE_TAXI,
        icon: 'fas fa-taxi',
        isActive: false,
        roles: [UserRoles.ADMIN, UserRoles.COMPANY_MANAGER, UserRoles.MANAGER],
        isCompanyBased: true
    },
    {
        name: 'NAVIGATION_ITEMS.RIDES',
        route: ROUTE_RIDE,
        icon: 'fas fa-route',
        isActive: false,
        roles: [UserRoles.ADMIN, UserRoles.COMPANY_MANAGER, UserRoles.MANAGER],
        isCompanyBased: true
    }
];
